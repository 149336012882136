<template>
  <Head>
    <title>{{ pageTitle }}</title>
    <meta name="og:title" :content="pageTitle" />
    <link
      rel="alternate"
      key="en"
      hreflang="en"
      :href="pathForLocale({ locale: 'en' })"
    />
    <link
      rel="alternate"
      key="es"
      hreflang="es"
      :href="pathForLocale({ locale: 'es' })"
    />
    <link
      rel="alternate"
      key="fr"
      hreflang="fr"
      :href="pathForLocale({ locale: 'fr' })"
    />

    <link
      rel="alternate"
      key="de"
      hreflang="de"
      :href="pathForLocale({ locale: 'de' })"
    />

    <link
      rel="alternate"
      key="it"
      hreflang="it"
      :href="pathForLocale({ locale: 'it' })"
    />

    <link
      rel="alternate"
      key="ja"
      hreflang="ja"
      :href="pathForLocale({ locale: 'ja' })"
    />

    <link
      rel="alternate"
      key="pt"
      hreflang="pt"
      :href="pathForLocale({ locale: 'pt' })"
    />

    <link
      rel="alternate"
      key="ru"
      hreflang="ru"
      :href="pathForLocale({ locale: 'ru' })"
    />

    <link
      rel="alternate"
      key="zh"
      hreflang="zh"
      :href="pathForLocale({ locale: 'zh' })"
    />
  </Head>
  <div :class="[online ? 'online' : 'offline', 'page page--elevation']">
    <AdUnit unit="1" />
    <h1>
      <router-link :to="pathForLocale({ path: '/' })">
        {{ $t("site-title") }}
      </router-link>
    </h1>
    <ElevationReading />
    <SetLocation />
    <AdUnit unit="2" />
    <Wikipedia :articleTitle="wikipedia" />
    <ShareActions />
    <AdUnit unit="3" />
    <TheFooter :localized="true" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { parseUrlTitle, pathForLocale, isHeadless } from "@/helpers";
import ElevationReading from "@/components/ElevationReading.vue";
import AdUnit from "@/components/AdUnit.vue";
import SetLocation from "@/components/SetLocation.vue";
import Wikipedia from "@/components/Wikipedia.vue";
import ShareActions from "@/components/ShareActions.vue";
import TheFooter from "@/components/TheFooter.vue";
import { Head } from "@vueuse/head";

export default {
  components: {
    ElevationReading,
    SetLocation,
    AdUnit,
    TheFooter,
    ShareActions,
    Wikipedia,
    Head
  },
  props: {
    title: { type: String },
    latitude: { type: String },
    longitude: { type: String },
    wikipedia: { type: String }
  },
  data() {
    return {
      online: isHeadless ? true : navigator.onLine
    };
  },
  computed: {
    ...mapState(["elevation", "location"]),
    formattedTitle() {
      return parseUrlTitle(this.title);
    },
    pageTitle() {
      return this.formattedTitle
        ? `${this.formattedTitle} - ${this.$t("site-title")}`
        : this.$t("site-title");
    },
    hasElevationValue() {
      return this.$store.getters.hasElevationValue;
    }
  },
  methods: {
    updateLocation() {
      this.$store.dispatch("updateLocation", {
        latitude: parseFloat(this.latitude),
        longitude: parseFloat(this.longitude),
        title: this.formattedTitle
      });
      this.$store.dispatch("fetchElevation").then(() => {
        this.$store.dispatch("setLocationOpen", false);
      });
    },
    updateOnlineStatus() {
      this.online = navigator.onLine;
    },
    pathForLocale
  },
  async created() {
    this.$store.dispatch(
      "setUseFeet",
      this.$t("units.feet-default") === "true"
    );
    if (this.latitude && this.longitude) {
      this.updateLocation();
    } else {
      this.$store.dispatch("watchUserLocation");
    }
    if (!isHeadless()) {
      window.addEventListener("online", this.updateOnlineStatus);
      window.addEventListener("offline", this.updateOnlineStatus);
    }
  },
  watch: {
    longitude() {
      this.updateLocation();
    }
  }
};
</script>
